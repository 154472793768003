import { Component, Vue } from 'vue-property-decorator';
import { Logger } from 'fsts';

const logger = new Logger('process-export');

@Component({
  components: {},
})
export default class ProcessExportView extends Vue {
  exportProcessItems = [
    // demo data for demonstration until implementation of the backend logic (comment to hide this data in the table)
    { createDate: '28.09.2021 um 14:10 Uhr' },
    { createDate: '24.09.2021 um 14:10 Uhr' },
    { createDate: '12.09.2021 um 14:10 Uhr' },
  ];

  private exports: any = {
    searchParams: {},
  };
  get headers() {
    const headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
    }[] = [
      { text: this.$t('create_date'), value: 'createDate' },
      {
        text: this.$t('download'),
        value: 'actions',
        sortable: false,
        width: '1%',
      },
    ];
    return headers;
  }

  onClickDownload(item: any) {
    logger.debug('onClickDownload');
  }
}
